<macro|{hasModal}| name='renderList'>
    <${hasModal ? 'div' : null} class="nav-modal">
        <ul>
            <li><a href="#users" on-click('scrollIfNeeded')>For Users</a></li>
            <li><a href="#places" on-click('scrollIfNeeded')>For Places</a></li>
            <li><a href="#contact" on-click('scrollIfNeeded')>Contact Us</a></li>
            <if(hasModal)>
                <li><a href="https://www.tiktok.com/@datsfireapp">TikTok</a></li>
                <li><a href="https://www.instagram.com/datsfireapp/">Instagram</a></li>
            </if>
        </ul>
    </>
</macro>

<nav>
    <ebay-icon-button on-click('handleClick')>
        <ebay-menu-20-icon />
    </ebay-icon-button>
    <renderList ...state/>
</nav>
