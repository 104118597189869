export default class {
    onCreate() {
        this.state = {
            hasModal: false
        }
    }
    handleClick() {
        this.toggle();
    }
    toggle() {
        this.state.hasModal = !this.state.hasModal;
        document.documentElement.classList[this.state.hasModal ? 'add' : 'remove']('modal-visible');
    }
    scrollIfNeeded(e, link) {
        e.preventDefault();
        if (this.state.hasModal) {
            this.toggle();
        }
        
        setTimeout(() => {
            const el = document.getElementById(link.getAttribute('href').slice(1));
            el.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        });
    }
}